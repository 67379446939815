class Gallery {
  constructor(item, parentEl) {
    this.item = item;
    this.parentEl = parentEl;
    this.splideContainer = null;

  }

  getData() {
    return new Promise((resolve, reject) => {
      const imageSizes = this.item.data.images.map(this.getSizeImage);

      Promise.all(imageSizes).then((res) => {
        if (this.item.data.type === 'block-gallery-grid-1') {
          resolve(this.getArrVertical(res));
        } else if (this.item.data.type === 'block-gallery-grid-2') {
          resolve(this.getArrHorisontal(res));
        }
      }).catch();
    });
  }

  getSplideContainer() {
    return new Promise(async (resolve, reject) => {
      if (!this.splideContainer) {
        this.splideContainer = await this.initSplideWithImages(this.item.data.images)
      }
      resolve(this.splideContainer);
    });
  }

  renderGrid(type, items) {
    const lineGridContainer = document.createElement('div');
    lineGridContainer.classList.add('line-grid-container');

    const blockGalleryGrid = document.createElement('div');
    blockGalleryGrid.classList.add('block-gallery-grid', type);

    const createColContainer = () => {
      const colContainer = document.createElement('div');
      colContainer.classList.add('col');

      return colContainer;
    };

    const createRow = (image, index) => {
      const containerRow = document.createElement('div');

      containerRow.classList.add('item');
      containerRow.classList.add('animate-appear');
      containerRow.style.width = `${image.widthPercent}%`;

      const createImage = (sizeKey, classString) => {
        const img = new Image();

        img.classList.add('img-view');
        img.classList.add('dont-animate');
        img.classList.add(classString);
        // img.classList.add(sizeKey);
        img.onerror = function () {
          Helper.onErrorImage(this);
          this.style.height = '100%';
        };
        img.src = image[sizeKey] || image['big_1920'];
        img.alt = image[sizeKey] || image['big_1920'];
        img.style.width = '100%';
        img.style.height = 'auto';
        img.data = {
          current: this.item.data.images.map(i => i.id).indexOf(image.id), // + 1 || this.item.data.images.map(i => i.src).indexOf(img.src) + 1) - 1,
          imgList: this.item.data.images,
          getSplideContainer: () => this.getSplideContainer(),
        };
        return img;
      }

      const imagesDiv = document.createElement('div');
      imagesDiv.classList.add('image-container');
      imagesDiv.style.position = 'relative';
      imagesDiv.style.paddingBottom = `${image.ratioToH * 100}%`;
      imagesDiv.style.backgroundColor = image.image_dominant_color;
      imagesDiv.appendChild(createImage('big_thumb', 'thumb-image'));
      imagesDiv.appendChild(createImage('big_1920', 'big-image'));

      containerRow.appendChild(imagesDiv);
      return containerRow;
    };

    items.forEach((col, i) => {
      const colEl = createColContainer();

      col.forEach((row, j) => {
        const index = (i + 1) * (j + 1);
        colEl.appendChild(createRow(row, index - 1));
      });

      blockGalleryGrid.appendChild(colEl);
    });

    lineGridContainer.appendChild(blockGalleryGrid);

    return lineGridContainer;
  }

  /**
  * @param {{
  *   id: string;
  * }} img
  * @return { Promise<{ ratioToW: number, ratioToH: number }> }
  */
  getSizeImage(img) {
    return new Promise((res, rej) => {
      if (!img.width || !img.height) {
        const image = new Image();

        image.onload = function () {
          res({
            ...img,
            ratioToW: image.naturalWidth / image.naturalHeight,
            ratioToH: image.naturalHeight / image.naturalWidth,
          });
        };

        image.src = img.big_1920;
      } else {
        res({
          ...img,
          ratioToW: img.width / img.height,
          ratioToH: img.height / img.width,
        });
      }
    });
  }

  /**
  *Promise<{ ratioToW: number, ratioToH: number }>
  * @param {{
  *   id: string;
  * }[]} sizes
  * @return { Array<any[]> }
 */
  getArrHorisontal(sizes) {
    const imgInRow = this.getWidth() > 768 && 3 || 2;
    let arrayImages = new Array(Math.ceil(sizes.length / imgInRow)).fill(null);

    arrayImages = arrayImages
      .map(() => sizes.splice(0, imgInRow))
      .map((row) => {
        let countRatio = row.reduce((prev, curr) => prev += curr.ratioToW, 0);

        // if last row
        if (imgInRow > row.length) {
          countRatio += imgInRow - row.length;
        }

        return row.map((item) => ({ ...item, widthPercent: item.ratioToW / countRatio * 100 }));
      });

    return [...arrayImages];
  }

  /**
  *Promise<{ ratioToW: number, ratioToH: number }>
  * @param {{
  *   id: string;
  * }[]} sizes
  * @return { Array<any[]> }
  */
  getArrVertical(sizes) {
    const rowCount = this.getWidth() > 768 && 3 || 2;
    const widthEachCol = this.parentEl.getBoundingClientRect().width / rowCount - 10;
    const array = new Array(rowCount).fill(0);
    const arrayImages = new Array(rowCount).fill([]);

    sizes.forEach((imgSize) => {
      const minColValue = Math.min(...array);
      const currentCol = array.findIndex((val) => val === minColValue);

      array[currentCol] += widthEachCol * imgSize.ratioToH + 30;
      arrayImages[currentCol] = [...arrayImages[currentCol], imgSize];
    });

    return [...arrayImages];
  }

  getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth,
    );
  }

  async initSplideWithImages(images, index) {
    let imagesSlidesHTML = '';
    images.forEach((image) => {
      imagesSlidesHTML += /*html*/`
      <li class="splide__slide">
          <img src="${image.big_1920}" alt="${image.big_1920}" />
      </li>`;
    });
    var div = document.createElement('div');
    div.innerHTML =
      /*html*/`
      <div class="splide gallery-slider">
        <div class="splide__slider">
          <div class="splide__arrows">
            <div class="splide__arrow splide__arrow--prev"></div>
            <div class="splide__arrow splide__arrow--next"></div>
          </div>
          <div class="splide__track">
            <ul class="splide__list">
              ${imagesSlidesHTML}
            </ul>
          </div>
        </div>
      </div>`;
    const splideContainer = div.querySelector('.splide');

    const splide = new Splide(splideContainer, {
      perPage: 1,
      start: index,
      pagination: false,
      waitForTransition: false,
      arrows: 'slider'
    });

    // debugSplide(splide);

    splide.mount();

    splideContainer.__proto__.splide = splide;

    return new Promise((res, rej) => {
      setTimeout(() => {
        res(splideContainer);
      }, 1_000);
    })
  }
}


var debugSplide = (splide) => {
  const events = [
    'mounted',
    'click',
    'move',
    'moved',
    'active',
    'inactive',
    'visible',
    'hidden',
    'refresh',
    'updated',
    'resize',
    'resized',
    'drag',
    'dragging',
    'dragged',
    'scroll',
    'scrolled',
    'destroy',
    'arrows:mounted',
    'arrows:updated',
    'pagination:mounted',
    'pagination:updated',
    'navigation:mounted',
    'autoplay:play',
    'autoplay:pause',
    'autoplay:playing',
    'lazyload:loaded',
  ]
  events.forEach((event) => {
    splide.on(event, () => {
      console.log(event);
    });
  })
}























